import { FC, Suspense, useEffect, useMemo, useState } from 'react'
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { Spin } from 'antd'

import { routers } from 'router'
import {
  ADMIN_ACTIVE_KEY_MENU,
  IRouteConfigProps,
  listRouterMapping,
  ROUTER_NAME_LIST,
  RouterItemType
} from 'router/type.d'
import { useCheckIsLogedIn, IUseUserRole, useUserRole } from 'hooks'
import { GradientAdminLayout } from './Admin'
import { AuthenLayout } from './Authen'
import { BlankLayout } from './Blank'
import { PageWrapper } from './PageWrapper'

import { HaveNotPermission } from 'components/HaveNotPermission'

import { FeatureFlagType, StoreType } from 'redux/type'
import { IProps, LAYOUT_NAME } from './type.d'
// import { UserType } from 'services/user/type.d'
import { setCurrentListNotAccess } from 'redux/reducers/listAccessSlice'
import store from 'redux/store'
import { setCurrentPrevPath } from 'redux/reducers/prevPathSlice'
import {
  checkAccountIsDeactive,
  getPathFromAzure,
  getTokenIDJWT
} from 'helpers/string'
import jwt_decode from 'jwt-decode'
import { setCurrentIdToken } from 'redux/reducers/idTokenSlice'
import { setCurrentUser } from 'redux/reducers/userSlice'
import { UserType } from 'services/user/type'
import { BROADCAST_CHANNEL } from 'services/broadcast'
import { BROADCAST_CHANNEL_TYPES } from 'services/broadcast/type.d'
import { setIsDeactiveUser } from 'redux/reducers/isDeactiveUserSlice'
import { ETabs as WPSTab } from 'pages/WPS/type.d'
import { flagKey, setFeatureFlag } from 'redux/reducers/featureFlagSlice'
import UserSettingService from 'services/userSetting/index.service'

const LAYOUT: any = {
  [LAYOUT_NAME.ADMIN]: GradientAdminLayout,
  [LAYOUT_NAME.AUTHEN]: AuthenLayout,
  [LAYOUT_NAME.BLANK]: BlankLayout
}

const FallbackLoading: FC = (): JSX.Element => {
  return (
    <div className="fallback-loading">
      <Spin />
    </div>
  )
}

export const Layout: FC<IProps> = ({
  children,
  ...props
}: IProps): JSX.Element => {
  const history: any = useHistory()
  const location: any = useLocation()
  const [loadingGetFlag, setLoadingGetFlag] = useState<boolean>(false)

  const currentRoute: RouterItemType | null = useSelector(
    (state: StoreType): RouterItemType | null => state.router.current,
    shallowEqual
  )

  // const isLogoutScreen: boolean = window.location.href
  // .toLocaleLowerCase()
  // .includes('logout')

  const authenInfo: any = getTokenIDJWT(window.location.href)

  const isDeactiveAccount: boolean = checkAccountIsDeactive(
    window.location.href
  )

  const linkRedirect: any = getPathFromAzure(window.location.href)
    ? decodeURIComponent(getPathFromAzure(window.location.href))
    : '/'

  const listNotPrivatePath: any[] = routers
    .filter((item: RouterItemType): any => item.isPrivate === false)
    .map((item: RouterItemType): any => item.path)
  // const user: UserType = useSelector(
  //   (state: StoreType): UserType => state.user.currentUser,
  const user: UserType = useSelector(
    (state: StoreType): UserType => state.user.currentUser,
    shallowEqual
  )

  const listNotAccess: string[] = useSelector(
    (state: StoreType): string[] => state.listNotAccess.currentListNotAccess,
    shallowEqual
  )

  const isFlagDashboard: boolean = useSelector(
    (state: StoreType): boolean => state.isFlagDashboard.isFlagDashboard,
    shallowEqual
  )

  const featureFlag: FeatureFlagType = useSelector(
    (state: StoreType): FeatureFlagType => state.featureFlag,
    shallowEqual
  )

  // tslint:disable-next-line: typedef
  useEffect(() => {
    if (
      window.self !== window.top ||
      process.env.REACT_APP_IS_LOGIN_COGNITO === 'true'
    ) {
      if (location.pathname.includes('/customer-support')) {
        sessionStorage.setItem('isCustomerSupport', '1')
      }
      // setPathRedirect(`${location.pathname}${location.search}`)
    }
    setTimeout((): void => {
      store.dispatch(
        setCurrentPrevPath(`${location.pathname}${location.search}`)
      )
    }, 0)

    const storage: string | string[] = JSON.parse(
      JSON.stringify(localStorage.getItem('listNonAccess'))
    )

    if (typeof storage === 'string') {
      store.dispatch(setCurrentListNotAccess(JSON.parse(storage)))
    } else {
      store.dispatch(setCurrentListNotAccess(storage))
    }

    // feature flag
    setLoadingGetFlag(true)
    UserSettingService.getFlagNonWps()
      .then((res: any): void => {
        store.dispatch(
          setFeatureFlag({
            value: res?.data?.payrollSenderFeatureFlag.toString() === 'true',
            keyName: flagKey.NON_WPS
          })
        )
        setLoadingGetFlag(false)
      })
      .catch((): void => {
        setLoadingGetFlag(false)
        store.dispatch(
          setFeatureFlag({
            value: false,
            keyName: flagKey.NON_WPS
          })
        )
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const role: IUseUserRole = useUserRole()

  const layoutName: LAYOUT_NAME = useMemo((): LAYOUT_NAME => {
    if (
      currentRoute &&
      currentRoute.adminActiveMenuKey &&
      currentRoute.adminActiveMenuKey ===
        ADMIN_ACTIVE_KEY_MENU.SALARY_PROCESSING
    ) {
      UserSettingService.getFlagNonWps()
        .then((res: any): any => {
          store.dispatch(
            setFeatureFlag({
              value: res?.data?.payrollSenderFeatureFlag.toString() === 'true',
              keyName: flagKey.NON_WPS
            })
          )
        })
        .catch((): any => {
          store.dispatch(
            setFeatureFlag({
              value: false,
              keyName: flagKey.NON_WPS
            })
          )
        })
    }
    return currentRoute?.layout || LAYOUT_NAME.BLANK
  }, [currentRoute])

  const CurrentLayout: FC =
    //  isLogoutScreen
    //   ? BlankLayout
    //   :
    user ? LAYOUT[layoutName] || BlankLayout : AuthenLayout

  const isLoggedIn: boolean = useCheckIsLogedIn(user)

  useEffect((): any => {
    if (
      window.self === window.top &&
      process.env.REACT_APP_IS_LOGIN_COGNITO !== 'true'
    ) {
      if (isDeactiveAccount) {
        setTimeout((): void => {
          store.dispatch(setIsDeactiveUser(isDeactiveAccount))
        })
        return
      }
      if (Boolean(authenInfo)) {
        store.dispatch(setCurrentIdToken(authenInfo))
        store.dispatch(setCurrentUser(jwt_decode(authenInfo)))
        localStorage.setItem('user', JSON.stringify(jwt_decode(authenInfo)))
        localStorage.setItem('idToken', authenInfo)
        setTimeout((): void => {
          BROADCAST_CHANNEL.postMessage({
            type: BROADCAST_CHANNEL_TYPES.SIGN_IN
          })
        })
        // if (linkRedirect.includes('/customer-support')) {
        //   sessionStorage.setItem('isCustomerSupport', '1')
        //   if (
        //     linkRedirect &&
        //     linkRedirect !==
        //       '/customer-support/customers?activeTab=HUB_ACCOUNTS' &&
        //     listNotPrivatePath.includes(linkRedirect) === false
        //   ) {
        //     history.push(linkRedirect)
        //     store.dispatch(setCurrentPrevPath(null))
        //   } else {
        //     history.push('/customer-support/customers?activeTab=HUB_ACCOUNTS')
        //   }
        // } else
        if (
          linkRedirect &&
          listNotPrivatePath.includes(linkRedirect) === false
        ) {
          history.push(linkRedirect)
          store.dispatch(setCurrentPrevPath(null))
        } else {
          history.push('/')
        }
      }
    }
  }, [authenInfo]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CurrentLayout>
      <Suspense fallback={<FallbackLoading />}>
        <Switch>
          {routers.map((router: RouterItemType, index: number): JSX.Element => {
            const { path, exact, isPrivate, component: Page } = router

            return (
              <Route
                path={path}
                exact={exact}
                key={index}
                render={(routeProps: any): any => {
                  if (loadingGetFlag) {
                    return <FallbackLoading />
                  }

                  const isDashboardBlank: boolean =
                    !isFlagDashboard &&
                    router.name === ROUTER_NAME_LIST.DASHBOARD

                  const haveNoRightToAccess: boolean = Boolean(
                    listNotAccess?.includes(listRouterMapping[router.name])
                  )
                  const havePermissionToAccess: boolean =
                    !router.accessWhiteList
                      ? true
                      : role.isInsideGroup(router.accessWhiteList)

                  if (
                    location?.search?.toString()?.includes(WPSTab.NON_WPS) &&
                    !featureFlag[flagKey.NON_WPS]
                  ) {
                    return <Redirect to="/salary-processing" />
                  }

                  if (isPrivate && !isLoggedIn) {
                    return <Redirect to="/login" />
                  }

                  if ((isPrivate && isLoggedIn) || !isPrivate) {
                    const routeConfig: IRouteConfigProps = {
                      layout: router.layout,
                      isPrivate: router.isPrivate,
                      adminActiveMenuKey: router.adminActiveMenuKey,
                      name: router.name
                    }

                    return (
                      <PageWrapper
                        Page={
                          isDashboardBlank
                            ? null
                            : havePermissionToAccess && !haveNoRightToAccess
                            ? Page
                            : HaveNotPermission
                        }
                        pageProps={{ ...props, ...routeProps, routeConfig }}
                        routeConfig={router}
                        location={location}
                      />
                    )
                  }

                  return null
                }}
              />
            )
          })}
        </Switch>
      </Suspense>
    </CurrentLayout>
  )
}
